/*eslint-disable*/
import React from "react";
// material-ui core components
import { List, ListItem, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { openCustomDialog } from "../config/reduxstore/actions/ui/openCustomDialog"

const useStyles = makeStyles((theme) => ({
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block",
        "&:hover": {
          color: theme.palette.primary.main
        }
      },
      left: {
        float: "left!important",
        display: "block"
      },
      right: {
        float: "right!important",
        display: "block"
        // padding: "15px 0",
        // margin: "0",
        // float: "right!important"
      },
      footer: {
        padding: "0.9375rem 0",
        textAlign: "center",
        display: "flex",
        zIndex: "2",
        position: "relative"
      },
      a: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        backgroundColor: "transparent"
      },
      footerWhiteFont: {
        "&,&:hover,&:focus": {
          color: "#FFFFFF"
        }
      },
      container: {
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "auto",
        marginLeft: "auto",
        width: "100%",
      },
      list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
      },
      inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
      },
      icon: {
        width: "18px",
        height: "18px",
        position: "relative",
        top: "3px"
      }
}));

export default function Footer() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleContact = () => {
    dispatch(
      openCustomDialog("dialogs/Contact")
    )
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Typography
                onClick={handleContact}
                className={classes.block}
              >
                Contact
              </Typography>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <a
                href="http://localhost:3000"
                className={classes.block}
                target="_blank"
              >
                About
              </a>
            </ListItem> */}
          </List>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Typography
                className={classes.block}
              >
              <Link href="https://www.vixalopex.com/" underline="none">
                &copy; {1900 + new Date().getYear()}, created by Vixalopex LLC
              </Link>
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}
