/* eslint-disable */
// user action constants
import {
  CREATE_USER_SUCCESS,
  USER_AUTH_SUCCESS,
  USER_TOKEN_EXPIRED,
  CURRENT_USER,
  DELETE_USER_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  UPDATE_USER_SUCCESS
} from "../actions/types";
import { getLocalStorage } from '../../utilities/localStorage'
const userId = getLocalStorage("userId")
const userSiteId = getLocalStorage("userSiteId")
const userLevel = getLocalStorage("userLevel")

// default user state
const defaultState = {
  authenticated: false,
  token: "",
  currentUser: {
    id: userId || null,
    username: null,
    user_level: userLevel || null,
    user_site: userSiteId || null,
    email: null,
    last_login: null
  },
  newUser: {},
  users: []
};

// user reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        token: action.payload,
      };

    case CURRENT_USER:
      return {
        ...state,
        currentUser: {
          id: action.payload.id,
          username: action.payload.username,
          user_level: action.payload.user_level,
          user_site: action.payload.user_site,
          email: action.payload.email,
          last_login: action.payload.last_login,
        }
      }

    case USER_TOKEN_EXPIRED:
      return {
        ...state,
        authenticated: false,
        token: "",
        currentUser: {
          id: null,
          username: null,
          user_level: null,
          user_site: null,
          email: null,
          last_login: null
        },
      };

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case DELETE_USER_SUCCESS:
      const filteredData = state.users.filter(user => user.id !== action.payload)
      return {
        ...state,
        users: filteredData
      }

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.concat(action.payload),
        newUser: action.payload
      }

    case UPDATE_USER_SUCCESS:
      const filteredArray = state.users.filter(user => user.id !== parseInt(action.payload.id))
      filteredArray.push(action.payload);
      return {
        ...state,
        users: filteredArray,
      }

    default:
      return state;
  }
};
