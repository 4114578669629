/* eslint-disable */
// user action constants
import {
    USER_SITE,
    CREATE_USER_SITE_SUCCESS,
    USER_SITES_SUCCESS,
    UPDATE_USER_SITES_SUCCESS
  } from "../actions/types";
import { getLocalStorage } from '../../utilities/localStorage'
const userSiteId = getLocalStorage("userSiteId")
const userSiteName = getLocalStorage("userSiteName")
  
  // default user state
  const defaultState = {
    userSiteName: {
      id: userSiteId || null,
      site_name: userSiteName || null
    },
    newSite: {},
    userSites: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case USER_SITE:
        return {
          ...state,
          userSiteName: {
            id: action.payload.id,
            site_name: action.payload.site_name
          }
        }

      // case CREATE_USER_SITE_SUCCESS:
      //   return {
      //     ...state,
      //     newSite: action.payload
      //   }

      case USER_SITES_SUCCESS:
        return {
          ...state,
          userSites: action.payload
        }

      case CREATE_USER_SITE_SUCCESS:
        return {
          ...state,
          userSites: state.userSites.concat(action.payload),
          newSite: action.payload
        }
  
      case UPDATE_USER_SITES_SUCCESS:
        const filteredArray = state.userSites.filter(site => site.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          userSites: filteredArray,
        }
    

      default:
        return state;
    }
  };
  