/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage } from './config/utilities/localStorage'
import { authSuccess } from './config/reduxstore/actions/user/authSuccess'
import Main from "./components/Main";
import Login from "./components/Login";
import CustomDialog from "./components/customDialog/CustomDialog";
import Alert from "./components/alert/Alert"
import getUser from "./config/requests/user/getUser";
import getUserSite from "./config/requests/userSite/getUserSite"
import Footer from "./components/Footer";

function App() {
  const authenticated = useSelector((state) => state.user.authenticated);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // // auto relog when a user token exists
  const token = getLocalStorage("token")
  if (token) {
    dispatch(authSuccess(token));
  }
  const userId = getLocalStorage("userId")
  const userSiteId = getLocalStorage("userSiteId")
  if (!authenticated && token && userId) {
    getUser(userId, dispatch, setLoading)
    getUserSite(userSiteId, dispatch, setLoading)
  }
  return <div className="App">{authenticated && token ? <Main /> : <Login />}<CustomDialog /><Alert /><Footer /></div>;
}

export default App;