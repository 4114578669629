// set local storage item
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

// get local storage item
export const getLocalStorage = key => {
  return localStorage.getItem(key)
}

// clear local storage item
export const clearLocalStorage = () => {
  localStorage.clear()
}