// user action constants
import { CURRENT_USER } from '../types'

export const currentUser = (id, username, user_level, user_site, email, last_login) => ({
  type: CURRENT_USER,
  payload: {
    id,
    username,
    user_level,
    user_site,
    email,
    last_login,
  }
})