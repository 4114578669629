// api config
import { API_URL } from './config'
// inmates
export const apiGetAllInmatesBySite = (siteId) => `${API_URL}/inmates?site_id=${siteId}`

export const apiAddInmate = () => `${API_URL}/inmates`
export const apiGetAllInmates = () => `${API_URL}/inmates`
export const apiGetOneInmate = (inmateId) => `${API_URL}/inmate/${inmateId}`
export const apiUpdateInmate = (inmateId) => `${API_URL}/inmate/${inmateId}`
export const apiDeleteInmate = (inmateId) => `${API_URL}/inmate/${inmateId}`

export const apiInmatesReport = () => `${API_URL}/inmates/stats`
export const apiInmatesReportAdmin = () => `${API_URL}/inmates/admin/stats`
export const apiDeleteInmatesByDate = () => `${API_URL}/inmates/delete`

// discipline

export const apiGetAllDisciplineBySite = (siteId) => `${API_URL}/discipline?site_id=${siteId}`

export const apiAddDiscipline = () => `${API_URL}/discipline`
export const apiGetAllDiscipline = () => `${API_URL}/discipline`
export const apiGetOneDiscipline = (inmateId) => `${API_URL}/discipline/${inmateId}`
export const apiUpdateDiscipline = (inmateId) => `${API_URL}/discipline/${inmateId}`
export const apiDeleteDiscipline = (inmateId) => `${API_URL}/discipline/${inmateId}`

export const apiDisciplineReport = () => `${API_URL}/discipline/stats`
export const apiDisciplineReportAdmin = () => `${API_URL}/discipline/admin/stats`
// export const apiDeleteDisciplineByDate = () => `${API_URL}/discipline/delete`

// discipline stats
export const apiCountAllDisciplineBySite = (siteId) => `${API_URL}/inmates/count?site_id=${siteId}`

// admin api calls
export const apiAdminCountAllInmates = () => `${API_URL}/inmates/count`
export const apiAdminCountActiveInmates = () => `${API_URL}/inmates/count?in_use=Active`
export const apiAdminCountInactiveInmates = () => `${API_URL}/inmates/count?in_use=Inactive`
export const apiAdminCountAllDiscipline = () => `${API_URL}/discipline/count`

// inmate stats
export const apiCountAllInmatesBySite = (siteId) => `${API_URL}/inmates/count?site_id=${siteId}`
export const apiCountActiveInmatesBySite = (siteId) => `${API_URL}/inmates/count?site_id=${siteId}&in_use=Active`
export const apiCountInactiveInmatesBySite = (siteId) => `${API_URL}/inmates/count?site_id=${siteId}&in_use=Inactive`

// user details
export const apiRegister = () => `${API_URL}/register`
export const apiUserAuth = () => `${API_URL}/login`
export const apiUpdateUserPassword = () => `${API_URL}/user/password`

// user
export const apiGetUser = (userId) => `${API_URL}/user/${userId}`
export const apiGetAllUsers = () => `${API_URL}/users`
export const apiGetAllUsersBySite = (siteId) => `${API_URL}/users?site_id=${siteId}`
export const apiUpdateUser = (userId) => `${API_URL}/user/${userId}`
export const apiDeleteUser = (userId) => `${API_URL}/user/${userId}`

// user site
export const apiGetUserSite = (siteId) => `${API_URL}/usersites/${siteId}`
export const apiUpdateUserSite = (siteId) => `${API_URL}/usersites/${siteId}`
export const apiGetUserSites = () => `${API_URL}/usersites`
export const apiAddUserSite = () => `${API_URL}/usersites`

// contact
export const apiEmail = () => `https://ing0g4m48e.execute-api.us-east-1.amazonaws.com/vixalopexstageapi/jailpuffmailer`