import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import ui from "../reducers/ui";
import user from "../reducers/user";
import inmate from "../reducers/inmate";
import discipline from "../reducers/discipline";
import userSite from "../reducers/userSite";
import email from "../reducers/email";

import { DESTROY_SESSION } from "../actions/types";
// Combine all reducers.
const appReducer = combineReducers({
  ui,
  user,
  inmate,
  discipline,
  userSite,
  email
});

const rootReducer = (state, action) => {   
   // Clear all data in redux store to initial.
   if(action.type === DESTROY_SESSION)
      state = undefined;
   return appReducer(state, action);
};

export default createStore(rootReducer, devToolsEnhancer());
