/* eslint-disable */
// request urls
import { apiGetUserSite } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'
import { setLocalStorage } from '../../utilities/localStorage'

// actions
import { userSiteName } from '../../reduxstore/actions/userSite/userSiteName'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (userSiteId, dispatch, setLoading) => {
  const request = await get(apiGetUserSite(userSiteId))

  const { data, error } = request
  // handle success
  data.data.length > 0  && setLocalStorage('userSiteName', data.data[0].site_name)
  data.data.length > 0  && dispatch(userSiteName(data.data[0].id, data.data[0].site_name))

  error && dispatch(setAlert('error', error))
  error && setLoading(false)
}
