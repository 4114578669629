/* eslint-disable */
// request urls
import { apiGetUserSites } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getUserSites } from '../../reduxstore/actions/userSite/getUserSites'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (dispatch, setLoading) => {
  const request = await get(apiGetUserSites())

  const { data, error } = request

  // handle success
  data && dispatch(getUserSites(data.data))

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
