// ui action constants
import {
  RETRIEVE_ALL_INMATES_SUCCESS,
  CREATE_INMATE_SUCCESS,
  // RETRIEVE_INMATE_SUCCESS,
  UPDATE_INMATE_SUCCESS,
  DELETE_INMATE_SUCCESS,
  ALL_INMATES_COUNT,
  ACTIVE_INMATES_COUNT,
  INACTIVE_INMATES_COUNT,
  INMATE_REPORT,
  DELETE_INMATES_BY_DATE_SUCCESS
} from "../actions/types";

// default ui reducer
const defaultState = {
  allInmates: [],
  newInmate: {},
  activeInmate: {},
  updateInmate: {},
  deleteInmate: {},
  activeInmatesCount: 0,
  inactiveInmatesCount: 0,
  allInmatesCount: 0,
  inmateReport: [],
  deletedInmates: []
}

// ui reducer
export default function init(state = defaultState, action) {
  switch (action.type) {

    case RETRIEVE_ALL_INMATES_SUCCESS:
      return {
        ...state,
        allInmates: action.payload
      }

    case INMATE_REPORT:
      return {
        ...state,
        inmateReport: action.payload
      }

    case ALL_INMATES_COUNT:
      return {
        ...state,
        allInmatesCount: action.payload
      }

    case ACTIVE_INMATES_COUNT:
      return {
        ...state,
        activeInmatesCount: action.payload
      }

    case INACTIVE_INMATES_COUNT:
      return {
        ...state,
        inactiveInmatesCount: action.payload
      }

    case CREATE_INMATE_SUCCESS:
      return {
        ...state,
        allInmates: state.allInmates.concat(action.payload)
      }

    case UPDATE_INMATE_SUCCESS:
      const filteredArray = state.allInmates.filter(inmate => inmate.id !== parseInt(action.payload.id))
      filteredArray.push(action.payload);
      return {
        ...state,
        allInmates: filteredArray,
      }


    case DELETE_INMATE_SUCCESS:
      const filteredData = state.allInmates.filter(inmate => inmate.id !== action.payload)
      return {
        ...state,
        allInmates: filteredData
      }

    case DELETE_INMATES_BY_DATE_SUCCESS:
      return {
        ...state,
        deletedInmates: action.payload
      }

    default:
      return state
  }
}
