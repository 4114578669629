/* eslint-disable */
// api config
import { API_CONFIG_TIMEOUT } from '../../api/config'

// response handler utility
import responseHandler from './responseHandler'
import { getLocalStorage } from '../localStorage'

// post fetch utility
export default (url = '', postBody = {}) => {
  const controller = new AbortController()
  const signal = controller.signal

  const token = getLocalStorage("token")
  let headers = {"Content-Type": "application/json"};
  if (token) {
    headers["Authorization"] = `Token ${token}`;
  }

  const request = fetch(url, {
    signal,
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(postBody)
  })

  setTimeout(() => controller.abort(), API_CONFIG_TIMEOUT)

  return responseHandler(request)
}
