// ui action constants
import {
    RETRIEVE_ALL_DISCIPLINE_SUCCESS,
    CREATE_DISCIPLINE_SUCCESS,
    // RETRIEVE_DISCIPLINE_SUCCESS,
    // RETRIEVE_DISCIPLINE_BY_INMATE_ID_SUCCESS,
    UPDATE_DISCIPLINE_SUCCESS,
    DELETE_DISCIPLINE_SUCCESS,
    ALL_DISCIPLINE_COUNT,
    DISCIPLINE_REPORT,
    DELETE_DISCIPLINE_BY_DATE_SUCCESS
  } from "../actions/types";
  
  // default ui reducer
  const defaultState = {
    allDiscipline: [],
    newDiscipline: {},
    updateDiscipline: {},
    deleteDiscipline: {},
    allDisciplineCount: 0,
    disciplineReport: [],
    deletedDiscipline: []
  }
  
  // ui reducer
  export default function init(state = defaultState, action) {
    switch (action.type) {
  
      case RETRIEVE_ALL_DISCIPLINE_SUCCESS:
        return {
          ...state,
          allDiscipline: action.payload
        }
  
      case DISCIPLINE_REPORT:
        return {
          ...state,
          disciplineReport: action.payload
        }
  
      case ALL_DISCIPLINE_COUNT:
        return {
          ...state,
          allDisciplineCount: action.payload
        }
  
      case CREATE_DISCIPLINE_SUCCESS:
        return {
          ...state,
          allDiscipline: state.allDiscipline.concat(action.payload)
        }
  
      case UPDATE_DISCIPLINE_SUCCESS:
        const filteredArray = state.allDiscipline.filter(discipline => discipline.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          allDiscipline: filteredArray,
        }
  
  
      case DELETE_DISCIPLINE_SUCCESS:
        const filteredData = state.allDiscipline.filter(discipline => discipline.id !== action.payload)
        return {
          ...state,
          allDiscipline: filteredData
        }
  
      case DELETE_DISCIPLINE_BY_DATE_SUCCESS:
        return {
          ...state,
          deletedDiscipline: action.payload
        }
  
      default:
        return state
    }
  }
  