import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      // main: "#4d81b7"
      main: "#BA1D24"
    },
    secondary: {
      // main: "#4d81b7"
      main: "#BA1D24"
    },
    error: {
      main: "#ff0008"
    },
    background: {
      main: "#fff"
    }
  },
  typography: {
    typography: {
      textDecoration: "line-through",
    },
  }
});

export default theme;
