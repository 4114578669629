//user
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_TOKEN_EXPIRED = "USER_TOKEN_EXPIRED";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const CURRENT_USER = "CURRENT_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

//user site
export const USER_SITE = "USER_SITE";
export const CREATE_USER_SITE_SUCCESS = "CREATE_USER_SITE_SUCCESS";
export const USER_SITES_SUCCESS = "USER_SITES_SUCCESS";
export const UPDATE_USER_SITES_SUCCESS = "UPDATE_USER_SITES_SUCCESS";

//ui
export const UI_SET_ALERT = "UI_SET_ALERT";
export const UI_SET_CONTENT = "UI_SET_CONTENT";
export const UI_OPEN_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_CLOSE_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_SET_ACTIVE_COMPONENT = "UI_SET_ACTIVE_COMPONENT";

// inmates
export const RETRIEVE_ALL_INMATES_SUCCESS = "RETRIEVE_ALL_INMATES_SUCCESS";
export const CREATE_INMATE_SUCCESS = "CREATE_INMATE_SUCCESS";
export const RETRIEVE_INMATE_SUCCESS = "RETRIEVE_INMATE_SUCCESS";
export const UPDATE_INMATE_SUCCESS = "UPDATE_INMATE_SUCCESS";
export const DELETE_INMATE_SUCCESS = "DELETE_INMATE_SUCCESS";
export const DELETE_INMATES_BY_DATE_SUCCESS = "DELETE_INMATES_BY_DATE_SUCCESS";

export const ALL_INMATES_COUNT = "ALL_INMATES_COUNT";
export const ACTIVE_INMATES_COUNT = "ACTIVE_INMATES_COUNT";
export const INACTIVE_INMATES_COUNT = "INACTIVE_INMATES_COUNT";

export const INMATE_REPORT = "INMATE_REPORT";

// email
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";

// discipline
export const RETRIEVE_ALL_DISCIPLINE_SUCCESS = "RETRIEVE_ALL_DISCIPLINE_SUCCESS";
export const CREATE_DISCIPLINE_SUCCESS = "CREATE_DISCIPLINE_SUCCESS";
export const RETRIEVE_DISCIPLINE_SUCCESS = "RETRIEVE_DISCIPLINE_SUCCESS";
export const RETRIEVE_DISCIPLINE_BY_INMATE_ID_SUCCESS = "RETRIEVE_DISCIPLINE_BY_INMATE_ID_SUCCESS";
export const UPDATE_DISCIPLINE_SUCCESS = "UPDATE_DISCIPLINE_SUCCESS";
export const DELETE_DISCIPLINE_SUCCESS = "DELETE_DISCIPLINE_SUCCESS";
export const DELETE_DISCIPLINE_BY_DATE_SUCCESS = "DELETE_DISCIPLINE_BY_DATE_SUCCESS";

export const ALL_DISCIPLINE_COUNT = "ALL_DISCIPLINE_COUNT";
export const DISCIPLINE_REPORT = "DISCIPLINE_REPORT";