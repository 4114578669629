/* eslint-disable */
// request urls
import { apiGetAllInmatesBySite, apiGetAllInmates } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getAllInmates } from '../../reduxstore/actions/inmate/retrieveAllInmates'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'
import { setActiveComponent } from "../../reduxstore/actions/ui/setActiveComponent"

export default async (userSite, dispatch, setLoading) => {
  const handleSetActiveComponent = (componentName) => {
    dispatch(
      setActiveComponent(`${componentName}`)
    )
  };
  let request = null
  if (userSite) {
    request = await get(apiGetAllInmatesBySite(userSite))
  } else {
    request = await get(apiGetAllInmates())
  }

  const { data, error } = request

  // handle success
  data.data && dispatch(getAllInmates(data.data))
  data.data && data.data.length > 0 ? handleSetActiveComponent(`Inmates`) : null

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
