/* eslint-disable */
import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  CssBaseline,
  Collapse,
  Menu,
  MenuItem
} from "@material-ui/core";
import { 
  ChevronLeft,
  ChevronRight,
  Assessment,
  Receipt,
  ExpandMore,
  ExpandLess,
  Person,
  Add,
  PieChart,
  LocationCity,
  Delete,
  Mail,
  Block
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu'
import ActiveComponent from './activeComponent/ActiveComponent'
import getAllInmates from "../config/requests/inmate/getAllInmates"
import getAllUsers from "../config/requests/user/getAllUsers"
import getAllDiscipline from "../config/requests/discipline/getAllDiscipline";
import getUserSite from "../config/requests/userSite/getUserSite";
import getUserSites from "../config/requests/userSite/getUserSites"
import { setActiveComponent } from "../config/reduxstore/actions/ui/setActiveComponent"
import { tokenExpired } from '../config/reduxstore/actions/user/tokenExpired'
import { clearLocalStorage } from "../config/utilities/localStorage"
import { openCustomDialog } from "../config/reduxstore/actions/ui/openCustomDialog"
import NoInmates from "./NoInmates";
// import logo from "../assets/jailpuff.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    fontSize: 22
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: theme.palette.background.default,
    display: "inline-block",
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    position: "relative",
    zIndex: "1",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      // backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
    }
  },
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
  },
  img: {
    width: drawerWidth,
    top: "0px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  },
}));

export default function Main() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const activeComponent = useSelector((state) => state.ui.activeComponent.component);
  const userSiteId = useSelector((state) => state.user.currentUser.user_site);
  const userLevel = useSelector((state) => state.user.currentUser.user_level);
  const userSiteName = useSelector(state => state.userSite.userSiteName.site_name)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (value) => {
    if (selectedIndex === value) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(value)
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userSiteId) {
      if (parseInt(userSiteId) === 1) {
        // console.log("IS 1", userSiteId)
        getAllInmates(false, dispatch, setIsLoading)
        getAllUsers(false, dispatch, setIsLoading)
        getAllDiscipline(false, dispatch, setIsLoading)
      } else {
        // console.log("NOT 1", userSiteId)
        getAllInmates(userSiteId, dispatch, setIsLoading)
        getAllUsers(userSiteId, dispatch, setIsLoading)
        getAllDiscipline(userSiteId, dispatch, setIsLoading)
      }
      getUserSite(userSiteId, dispatch, setIsLoading)
      getUserSites(dispatch, setIsLoading)
    }
  }, [dispatch, userSiteId])

  const handleSetActiveComponent = (componentName) => {
    dispatch(
      setActiveComponent(`${componentName}`)
    )
  };

  const handleLogout = () => {
    clearLocalStorage()
    dispatch(tokenExpired())
    dispatch({ type: "DESTROY_SESSION" });
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyProfile = () => {
    dispatch(
      openCustomDialog("dialogs/UserProfile")
    )
  };

  const handleContact = () => {
    dispatch(
        openCustomDialog("dialogs/Contact")
    )
  };

  // const brand = (
  //   <div className={classes.logo}>
  //       <div className={classes.logoImage}>
  //         <img src={logo} alt="logo" className={classes.img} />
  //       </div>
  //   </div>
  // );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} noWrap>
            JailPuff - {userSiteName}
          </Typography>
          <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Person />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleMyProfile}>My Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* {brand} */}
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        {userLevel === 1 &&
        <List>
          <ListItem button onClick={() => handleClick(0)}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Users" />
            {selectedIndex === 0 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 0 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Users`)}>
                <ListItemIcon>{<Person />}</ListItemIcon>
                <ListItemText primary={"Manage Users"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddUserView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add User"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(1)}>
            <ListItemIcon>
              <LocationCity />
            </ListItemIcon>
            <ListItemText primary="Sites" />
            {selectedIndex === 1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 1 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`UserSites`)}>
                <ListItemIcon>{<LocationCity />}</ListItemIcon>
                <ListItemText primary={"Manage Sites"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddUserSiteView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Site"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(2)}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Delete Bulk Data" />
            {selectedIndex === 2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 2 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`DeleteInmates`)}>
                <ListItemIcon>{<Delete />}</ListItemIcon>
                <ListItemText primary={"Delete Bulk Ecig/Inmate Data"} />
              </ListItem>
            </List>
          </Collapse>
        </List>
        }
        {userLevel === 2 &&
        <List>
          <ListItem button onClick={() => handleClick(3)}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Users" />
            {selectedIndex === 3 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 3 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Users`)}>
                <ListItemIcon>{<Person />}</ListItemIcon>
                <ListItemText primary={"Manage Users"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddUserView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add User"} />
              </ListItem>
            </List>
          </Collapse>
        </List>}
        <Divider />
        <List>
          <ListItem button onClick={() => handleClick(4)}>
            <ListItemIcon>
              <Receipt />
            </ListItemIcon>
            <ListItemText primary="Transactions" />
            {selectedIndex === 4 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 4 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Inmates`)}>
                <ListItemIcon>{<Receipt />}</ListItemIcon>
                <ListItemText primary={"Manage Transactions"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddInmateView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Transactions"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(5)}>
            <ListItemIcon>
              <Block />
            </ListItemIcon>
            <ListItemText primary="Discipline" />
            {selectedIndex === 5 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 5 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Discipline`)}>
                <ListItemIcon>{<Block />}</ListItemIcon>
                <ListItemText primary={"Manage Discipline"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddDisciplineView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Discipline"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(6)}>
            <ListItemIcon>
              <Assessment />
            </ListItemIcon>
            <ListItemText primary="Reports/Status" />
            {selectedIndex === 6 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 6 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Stats`)}>
                <ListItemIcon>{<PieChart />}</ListItemIcon>
                <ListItemText primary={"Status"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Reports`)}>
                <ListItemIcon>{<Assessment />}</ListItemIcon>
                <ListItemText primary={"Reports"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleContact()}>
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary="Contact Support" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {isLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {activeComponent ? <ActiveComponent /> : <NoInmates />}
          </div>
        )}
      </main>
    </div>
  );
}
