/* eslint-disable */
// request urls
import { apiGetAllUsers, apiGetAllUsersBySite } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getAllUsersSuccess } from '../../reduxstore/actions/user/getAllUsersSuccess'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (userSite, dispatch, setLoading) => {

  let request = null
  if (userSite) {
    request = await get(apiGetAllUsersBySite(userSite))
  } else {
    request = await get(apiGetAllUsers())
  }

  const { data, error } = request

  // handle success
  data && dispatch(getAllUsersSuccess(data.data))

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
